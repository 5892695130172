a[href="/pages/sample-request-form"] {
  border-radius: var(--button-border-radius);
  font-size: var(--font-size-base);
  text-transform: var(--button-text-transform);
  background: rgb(var(--color-button-background));
  color: rgb(var(--color-button-text));
  box-shadow: inset 0 0 0 var(--button-border-width) rgb(var(--color-button-border));
  box-sizing: border-box;
  font-weight: var(--main-font-weight);
  max-width: 250px;
  line-height: var(--base-line-height);
  margin-top: 15px;
  padding: .65rem;
  transition: transform .1s cubic-bezier(.215, .61, .355, 1);
  display: block;
  text-align: center !important;
}

a[href="/pages/sample-request-form"]:hover {
  color: #fff;
  background-color: #29beed;
  transition: all .4s;
}

#shopify-section-template--16333902741748__1650891033bae15b05 img {
  max-width: 125%;
  margin-left: -45px;
}

#shopify-section-template--16333902741748__1650891033bae15b05 .text-left {
  z-index: 100;
}

#shopify-section-template--16333902741748__1650891615011511eb .text-scheme-text {
  color: #29beed;
}

@media screen and (min-width: 768px) {
  #shopify-section-template--16387839983860__1661364403179a3fd6 .splide__slide {
    width: 25% !important;
    margin-right: 20px !important;
  }
}

#shopify-section-template--16387839983860__166136383275b2a63b .push-btn-surface {
  background-color: #d5ebf4;
}

#shopify-section-template--16387839983860__166136383275b2a63b .push-btn:after {
  background: linear-gradient(156deg, #a5ddf3, #29beed 49%, #07629f 100%);
}

#shopify-section-template--16387839983860__1661363444a0b4380d .py-section-vertical-spacing {
  padding: 0;
}

.product-block .text-base {
  font-size: 30px !important;
}

#shopify-section-template--16345998098676__1661307869f95d0dbc .text-scheme-text {
  color: #25e0b6;
}

#shopify-section-template--16385588658420__1661307869f95d0dbc .text-scheme-text {
  color: #29beed;
}

div button.scr-open-size-chart, div button.scr-open-size-chart:hover {
  color: #fff;
  background-color: #0000;
}

.scr-modal {
  border: 2px solid #29beed;
  border-radius: 20px;
}

#shopify-section-template--16387984228596__1661363444a0b4380d .py-section-vertical-spacing, #shopify-section-template--16387984228596__16613660023c9c0a71 .py-section-vertical-spacing {
  padding: 0;
}

.sub-footer {
  text-align: center;
  padding: 5px 0;
  font-size: 12px;
}

.sub-footer span {
  margin-right: 20px;
}

.hulk_form_3XQSfKhFCrH2Iw5qIM3w8g p {
  font-family: filson-pro, sans-serif !important;
}

@media screen and (max-width: 767px) {
  #shopify-section-template--16333902741748__1650891033bae15b05 img {
    max-width: 100%;
    margin-bottom: -75px;
    margin-left: -25px;
  }

  #shopify-section-template--16333902741748__166131363800f9cb67 .splide__arrows {
    margin-top: -70px;
  }
}

/*# sourceMappingURL=index.aa184e6d.css.map */
